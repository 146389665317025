// Configurations
export const ELEMENT_ROOT_ID = '#__next';
export const ELEMENT_MODAL_ID = '#modals'
export const ACCESS_TOKEN_KEY = 'cogitate:access_token';
export const EXTERNAL_API = process.env.NEXT_PUBLIC_EXTERNAL_API || 'EXTERNAL WEBSITE_HERE';

// HTML document defaults
export const DEFAULT_META_TITLE = 'Cogitate | We Create Experiences';
export const DEFAULT_META_DESCRIPTION = `Creating extraordinary technology & experiences paired with powerful, first principles reasoning. We strive to provide an invigorating environment where creativity is unleashed.`;

// Common default values
export const DEFAULT_LIMIT = 20;
export const DEFAULT_PAGE = 1;

// react-table pagination defaults
export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE_SIZES = [5, 10, 20] as const;
export const DEFAULT_PAGE_SIZE: typeof DEFAULT_PAGE_SIZES[number] = 5;


// Dimentions 
export const NAV_HEIGHT = 100

// Z-INDECES
export const Z_INDECES = {
  NAVBAR: 40,
  FLOATING_COMPONENT: 30,
  PARTNER_PORTAL: 35,
  MODAL_OVERLAY: 49,
  MODAL: 50,
  BACKGROUND_ASSETS: -10,
};
  