import { BLOG_PAGE, CAREERS_PAGE } from "@app/constants/routes";
import { useBlogRecent } from "@app/lib/fetcher/blog";
import { format } from "date-fns";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { chunk } from "lodash";
import { frontendApi } from "@app/lib/api";

const BottomFooter = () => {
  const [jobs, setJobs] = useState([]);
  const { recentBlogs } = useBlogRecent(2)

  useEffect(() => {
    async function init() {
      const { data } = await frontendApi.get('/jobs');

      setJobs(data);
    }
    init();
  }, [])

  const router = useRouter();
  return (
    <div className="flex flex-col space-y-6 md:space-y-12 max-w-[1280px] md:mx-auto">
      <div className="grid grid-cols-12 gap-y-10">
        <div
          className="col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-4
          flex flex-col md:items-start space-y-6"
        >
          <Image
            alt="logo"
            src="/assets/svgs/global/logo-white.svg"
            width={140}
            height={35}
          />
          <div
            className="flex flex-col space-y-8 text-slate-300 body-1 
            text-center md:text-left"
          >
            <span className="cursor-default">
              270 Sparta Ave, Sparta, NJ 07871
            </span>
            <a
              href="mailto:contact@cogitate.io"
              className="underline underline-offset-4 hover:text-white"
            >
              contact@cogitate.io
            </a>
          </div>
          <div className="flex flex-row space-x-4 justify-center">
            <Link href="https://www.facebook.com/cogitate.io" passHref>
            <span className="cursor-pointer h-8 w-8 flex justify-center items-center hover:bg-custom-white/10 hover:rounded-sm">
                <Image
                  alt="logo"
                  src="/assets/svgs/brands/facebook.svg"
                  width={24}
                  height={24}
                />
              </span>
            </Link>
            <Link href="https://twitter.com/cogitate_io" passHref>
              <span className="cursor-pointer h-8 w-8 flex justify-center items-center hover:bg-custom-white/10 hover:rounded-sm">
                <Image
                  alt="logo"
                  src="/assets/svgs/brands/twitter.svg"
                  width={24}
                  height={24}
                />
              </span>
            </Link>
            <Link href="https://www.linkedin.com/company/cogitate-io/mycompany/" passHref>
            <span className="cursor-pointer h-8 w-8 flex justify-center items-center hover:bg-custom-white/10 hover:rounded-sm">
                <Image
                  alt="logo"
                  src="/assets/svgs/brands/linkedin.svg"
                  width={24}
                  height={24}
                />
              </span>
            </Link>
          </div>
        </div>
        <div
          className="col-span-12 md:col-span-6 lg:col-span-7 xl:col-span-8
          grid grid-cols-12 gap-y-6 md:gap-y-16 lg:gap-x-12 xl:gap-x-10 
          text-center md:text-left"
        >
          <div className="hidden lg:block lg:col-span-2 xl:hidden" />
          <div className="col-span-12 lg:col-span-5 xl:col-span-4 lg:max-w-[181px] md:flex md:justify-end">
            <div className="flex flex-col space-y-4">
              <div
                className="text-custom-white hover:text-custom-white/70"
                onClick={() => router.push(BLOG_PAGE)}
              >
                <span className="font-black cursor-pointer">RECENT POSTS</span>
              </div>
              {recentBlogs.map((blog) => (
                <div
                  key={blog.title}
                  className="text-slate-300 hidden md:flex flex-col space-y-1 md:max-w-[190px] cursor-pointer hover:text-white"
                  onClick={() => router.push(blog.link)}
                >
                  <span className="body-1">{blog.title}</span>
                  <span className="label-sm-extra">{format(new Date(blog.datePosted), 'MMMM dd, yyyy')}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-12 lg:col-span-5 xl:col-span-8 body-1 md:flex md:justify-end xl:justify-start items-start">
            <div className="flex flex-col space-y-4">
              <div
                className="text-custom-white"
                onClick={() => router.push(CAREERS_PAGE)}
              >
                <span className="font-black cursor-pointer hover:text-custom-white/70">JOB OPENINGS</span>
              </div>
              <div className="flex flex-row">
                <div className="flex flex-col space-y-4">
                  {chunk(jobs, 5)[0]?.map((jobOpenning) => (
                    <span
                      key={jobOpenning.id}
                      className="text-slate-300 hidden md:block md:max-w-[180px] cursor-pointer hover:text-white"
                      onClick={() => router.push(`${CAREERS_PAGE}/${jobOpenning.id}`)}
                    >
                      {jobOpenning.name.replace(' (Remote/Onsite)', '')}
                    </span>
                  ))}
                </div>
                <div className="hidden xl:flex flex-col space-y-4">
                  {chunk(jobs, 5)[1]?.map((jobOpenning) => (
                    <span
                      key={jobOpenning.id}
                      className="text-slate-300 hidden md:block md:max-w-[190px] cursor-pointer hover:text-white"
                      onClick={() => router.push(`${CAREERS_PAGE}/${jobOpenning.id}`)}
                    >
                      {jobOpenning.name.replace(' (Remote/Onsite)', '')}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-4 md:space-y-8 items-center lg:items-stretch">
        <div className="w-full h-[1px] bg-[#374151]" />
        <div
          className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row-reverse lg:justify-between 
        text-center text-slate-300 body-3"
        >
          <span className="flex space-x-2">
            <span>Privacy Policy</span>
            <span>•</span>
            <span>Terms & Conditions</span>
            <span>•</span>
            <span>EULA</span>
          </span>
          <span>© 2022 Cogitate. All Rights Reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default BottomFooter;
