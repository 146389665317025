import useSWR from "swr"

export const useBlogs = (category = 'All Blogs', {
    search = '',
    limit = 6,
    offset = 0
} = {}) => {

    const { data, error, mutate } = useSWR(`/blogs?category=${category}&search=${search}&limit=${limit}&offset=${offset}`)

    return {
        blogs: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    }
}

export const useBlog = (blogKey: string) => {
    // Fixes issue of blogKey undefined
    // https://dev.to/wh1zk1d/swr-dynamic-routes-in-next-js-3cbl
    const { data, error } = useSWR(blogKey && `/blogs/${blogKey}`)

    return {
        blog: data,
        isLoading: !error && !data,
        isError: error
    }
}

export const useFeaturedBlogs = () => {
    const { data = [], error } = useSWR('/blogs/featured')

    return {
        featuredBlogs: data,
        isLoading: !error && !data,
        isError: error
    }
}

export const useBlogCategories = (skipAllBlogs = false) => {
    const { data = [], error } = useSWR('/blogs/categories')

    return {
        categories: skipAllBlogs ? data.filter((d) => d !== 'All Blogs') : data,
        isLoading: !error && !data,
        isError: error
    }
}

export const useBlogRecent = (limit = 4) => {
    const { data = [], error } = useSWR(`/blogs/recent?limit=${limit}`)

    return {
        recentBlogs: data,
        isLoading: !error && !data,
        isError: error
    }
}

export const useBlogsRandom = (limit = 2) => {
    const { data, error } = useSWR(`/blogs/random?limit=${limit}`)

    return {
        randomBlogs: data,
        isLoading: !error && !data,
        isError: error
    }
}