import { FunctionComponent, ReactNode, useMemo, ButtonHTMLAttributes } from "react";

interface Prop extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  buttonType?: "clear" | "round";
  size?: "sm" | "base";
};

const IconButton: FunctionComponent<Prop> = ({
  icon,
  buttonType = "clear",
  size = "base",
  ...otherProps
}) => {
  const buttonBgColor = useMemo(() => {
    switch (buttonType) {
      case "round":
        return "rounded-full gradient-border-purple";
      case "clear":
        return "text-[#F8FAFC] hover:bg-slate-300/20";
      default:
        return "bg-secondary hover:bg-secondary-dark disabled:opacity-50 text-custom-white";
    }
  }, [buttonType]);

  const buttonSize = useMemo(() => {
    switch (size) {
      case "sm":
        return "w-8 h-8";
      case "base":
      default:
        return "w-10 h-10";
    }
  }, [size]);

  const className = `${buttonBgColor} ${buttonSize} rounded-full flex justify-center items-center`;
  return (
    <button type="button" {...otherProps} className={className}>
      {icon}
    </button>
  );
};

export default IconButton;
