import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';

export const extractedTailwindConfig = resolveConfig(tailwindConfig);
export const extractedTailwindTheme = extractedTailwindConfig.theme;

/**
 * Helper function to simulate delay
 *
 * @param ms
 *
 * @usage await sleep(5000)
 */
export const sleep = (ms = 5000) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const capitalizeFirstChar = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export default sleep;