/**
 * This are all the available routes supported by the project. This
 * should match to the contents of "pages" directory.
 *
 * Refer to: https://nextjs.org/docs/basic-features/pages
 *
 * NOTE: Additional pages should be suffixed with "_PAGE"
 */
 export const HOME_PAGE = '/';
 export const ABOUT_PAGE = '/about';
 export const BLOG_PAGE = '/blog';
 export const BLOG_SEARCH_PAGE = '/blog/search';
 export const CONTACT_US_PAGE = '/contact-us';
 export const CAREERS_PAGE = '/careers';

 export const ERROR_PAGE = '/500';
 export const OFFLINE_PAGE = '/_offline';
 export const MISSING_PAGE = '/404';
