import { CAREERS_PAGE } from "@app/constants/routes";
import { useThemeContext } from "@app/contexts/theme-context";
import Image from "next/image";
import { useRouter } from "next/router";
import Button from "../button";

const TopFooter = () => {
  const { isMobile } = useThemeContext();
  const router = useRouter();
  return (
    <div className="flex flex-col-reverse items-center md:grid md:grid-cols-12 md:gap-x-12 xl:px-20 max-w-[1280px] md:mx-auto">
      <div className="md:col-span-7 flex flex-col items-center md:items-start justify-center space-y-8">
        <div className="flex flex-col space-y-2 items-center md:items-start text-center md:text-left">
          <span className="h4 md:h3 max-w-[250px] md:max-w-[210px] lg:max-w-[400px] 2xl:max-w-none">
            Become a part of our team!
          </span>
          <span className="body-3 md:sub-heading-md">
            We are just getting started on our journey and we want you to be a
            part of it. We are looking for kind, motivated people to join us.
          </span>
        </div>
        <Button
          buttonType="gradient"
          paddingSize="lg"
          radiusSize="2xl"
          full={isMobile}
          onClick={() => router.push(CAREERS_PAGE)}
        >
          Join the team
        </Button>
      </div>
      <div className="md:col-span-5 relative">
        <div
          className="md:hidden"
          style={{
            willChange: "transform",
            animation: "move-sally 10s infinite linear alternate",
          }}
        >
          <Image
            style={{
              animation: "rotate-sally 10s infinite linear alternate",
            }}
            src="/images/footer/sally.png"
            alt="rocket"
            height={200}
            width={227}
          />
        </div>
        <div
          className="hidden md:block"
          style={{
            willChange: "transform",
            animation: "move-sally 10s infinite linear alternate",
          }}
        >
          <Image
            style={{
              animation: "rotate-sally 10s infinite linear alternate",
            }}
            layout="responsive"
            src="/images/footer/sally.png"
            alt="rocket"
            height={400}
            width={448}
          />
        </div>
        <div
          className="hidden xl:block absolute top-2 left-20 2xl:left-32"
          style={{
            willChange: "transform",
            animation: "sink-smoke-1 10s infinite linear alternate",
          }}
        >
          <Image
            style={{
              animation: "rotate-sally 10s infinite linear alternate",
            }}
            src="/images/footer/smoke-1.png"
            alt="smoke"
            height={65}
            width={65}
          />
        </div>
        <div
          className="hidden xl:block absolute bottom-2 right-0"
          style={{
            willChange: "transform",
            animation: "sink-smoke-2 10s infinite linear alternate",
          }}
        >
          <Image
            style={{
              animation: "rotate-sally 10s infinite linear alternate",
            }}
            src="/images/footer/smoke-2.png"
            alt="smoke"
            height={65}
            width={65}
          />
        </div>
        <div
          className="absolute xl:hidden
            top-2 md:top-0 lg:top-4 
            left-10 md:left-14 lg:left-24
          "
          style={{
            willChange: "transform",
            animation: "sink-smoke-1 10s infinite linear alternate",
          }}
        >
          <Image
            style={{
              animation: "rotate-sally 10s infinite linear alternate",
            }}
            src="/images/footer/smoke-1.png"
            alt="smoke"
            height={50}
            width={50}
          />
        </div>
        <div
          className="absolute xl:hidden
            -bottom-2 md:-bottom-2
            right-2
          "
          style={{
            willChange: "transform",
            animation: "sink-smoke-2 10s infinite linear alternate",
          }}
        >
          <Image
            style={{
              animation: "rotate-sally 10s infinite linear alternate",
            }}
            src="/images/footer/smoke-2.png"
            alt="smoke"
            height={50}
            width={50}
          />
        </div>
      </div>
    </div>
  );
};

export default TopFooter;
