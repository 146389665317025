import '@app/providers/intro-script';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import ThemeProvider from '@app/contexts/theme-context';
import { DEFAULT_META_TITLE, DEFAULT_META_DESCRIPTION, ELEMENT_MODAL_ID } from '@app/constants/default';
import '@app/styles/globals.css';
import '@app/styles/animations.css';
import 'react-toastify/dist/ReactToastify.css';
import NextNProgress from 'nextjs-progressbar';
import dynamic from 'next/dynamic'
import ToastContainer from '@app/components/toast-container';
import AppLayout from '@app/components/app-layout';
import { SWRConfig } from 'swr';
import { fetcher } from '@app/lib/api';
import { ParallaxProvider } from "react-scroll-parallax";

export const DyanicIsOffline = dynamic(
  () => import('@app/components/is-offline'),
  { ssr: false },
);

export interface AppProps extends NextAppProps {
  Component: NextAppProps['Component'] & { requireAuth: boolean }
}

const App = ({ Component, pageProps }: AppProps) => (
  <SWRConfig
    value={{
      refreshInterval: 60000,
      fetcher,
    }}
  >
    <ParallaxProvider>
      <ThemeProvider>
        <Head>
          <title>{DEFAULT_META_TITLE}</title>
          <meta name="description" content={DEFAULT_META_DESCRIPTION} />
          <meta
            name="viewport"
            // eslint-disable-next-line max-len
            content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,viewport-fit=cover"
          />
        </Head>
        <NextNProgress options={{ showSpinner: false }} color="#9D3FFC" />
        <AppLayout>
          <Component {...pageProps} />
        </AppLayout>
        <ToastContainer />
        <DyanicIsOffline />
        <div id={ELEMENT_MODAL_ID.replace("#", "")} />
      </ThemeProvider>
    </ParallaxProvider>
  </SWRConfig>
);

export default App;
