import Link from "next/link";

type Prop = {
  navRoutes: {
    label: string;
    path: string;
    hasDivider: boolean;
  }[];
};
const SidenNav = ({ navRoutes }: Prop) => {
  return (
    <div
      className="w-9/12 h-full shadow-2xl p-10 flex flex-col space-y-4"
      style={{
        background:
          "linear-gradient(103.9deg, rgba(255, 255, 255, .1), rgba(0, 0, 0, 0.5))",
        backdropFilter: "blur(20px)",
        WebkitBackdropFilter: "blur(20px)",
      }}
    >
      {navRoutes.map((route) => (
        <div key={route.path} className="flex flex-col space-y-4">
          <Link href={route.path} passHref>
            <span className="cursor-pointer text-custom-white hover:text-custom-white/50">
              {route.label}
            </span>
          </Link>
          {route.hasDivider && <div className="bg-custom-white h-[1px] w-full" />}
        </div>
      ))}
    </div>
  );
};

export default SidenNav;
