import { Fragment, ReactNode } from "react";
import { NAV_HEIGHT } from "@app/constants/default";
import Footer from "./footer.tsx";
import Navbar from "./navbar";
import { useRouter } from "next/router";
import { ABOUT_PAGE, BLOG_PAGE, HOME_PAGE } from "@app/constants/routes";

export interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const router = useRouter();
  const hidePadding = [HOME_PAGE, ABOUT_PAGE, BLOG_PAGE].includes(router.pathname) || /\/blog\/*/g.test(router.pathname);

  return (
    <Fragment>
      <Navbar />
      <div style={{
        paddingTop: hidePadding ? 0 : NAV_HEIGHT
      }}>{children}</div>
      <Footer />
    </Fragment>
  );
};

export default AppLayout;
