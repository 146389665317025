import { useMemo, ButtonHTMLAttributes, ReactNode } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  buttonType?:
  | 'violet'
  | 'gradient'
  | 'white'
  | 'gradient-border-indigo'
  full?: boolean;
  startIcon?: ReactNode;
  trailingIcon?: ReactNode;
  fontSize?:
  | 'sm'
  | 'label-md'
  | 'lg';
  paddingSize?:
  | 'sm'
  | 'custom-irregular'
  | 'custom-irregular-small'
  | 'lg';
  radiusSize?: 
    |'lg'
    | 'custom-half'
    | '2xl';
}

const Button = ({
  buttonType = 'violet',
  fontSize = 'sm',
  radiusSize = 'lg',
  paddingSize = 'sm',
  className = '',
  full,
  children,
  startIcon,
  trailingIcon,
  style = {},
  ...otherProps
}: ButtonProps) => {
  const buttonBgColor = useMemo(() => {
    switch (buttonType) {
      case 'violet':
        return `bg-custom-violet text-custom-white hover:bg-[#3B199A]`;
      case 'white': 
        return `bg-custom-white text-custom-violet hover:bg-custom-violet hover:text-custom-white `
      case 'gradient':
        return 'bg-gradient-to-tr from-custom-pink to-custom-violet hover:from-custom-pink/50 hover:to-custom-violet/50';
      // Gradient border
      case 'gradient-border-indigo':
          return 'gradient-border-indigo hover:bg-custom-black/30';
      default:
        return 'bg-custom-violet text-light hover:bg-[#3B199A]';
    }
  }, [buttonType]);


  const buttonFont = useMemo(() => {
    switch (fontSize) {
      case 'sm': return 'label-sm xs:btn-text-sm';
      case 'label-md': return 'label-md'
      case 'lg':
      default: return 'btn-text-lg';
    }
  }, [fontSize]);

  const buttonPadding = useMemo(() => {
    switch (paddingSize) {
      case 'sm': return 'px-4 py-2';
      case 'custom-irregular': return buttonType === 'gradient-border-indigo' ? 'pl-4 pr-6 py-4' : 'pl-4 pr-6 py-3';
      case 'custom-irregular-small': return 'pl-4 pr-6 py-2';
      case 'lg':
      default: return 'px-4 py-3';
    }
  }, [paddingSize, buttonType]);

  const buttonRadius = useMemo(() => {
    switch (radiusSize) {
      case "lg":
        return "rounded-lg";
      case "custom-half":
        return "";
      case "2xl":
        return "rounded-2xl";
      default:
        return "rounded-2xl";
    }
  }, [radiusSize]);

  const isFull = full && 'w-full';
  const defaultClassStyles = `${buttonRadius} shadow-sm disabled:cursor-not-allowed
   focus:ring-2 focus:ring-offset-2 focus:ring-offset-custom-white focus:ring-secondary
   disabled:text-text-color-disabled disabled:shadow-none
   disabled:bg-background-mid-em`;
  const renderStartIcon = startIcon == null ? null : (<span className="mr-2">{startIcon}</span>);
  const renderTrailingIcon = trailingIcon == null ? null : (<span className="ml-2">{trailingIcon}</span>);
  const customStyle = radiusSize === 'custom-half' ? { borderRadius: '4px 40px 40px 4px' } : {};
  return (
    <button
      type="button"
      style={{
        ...customStyle,
        transition: 'opacity 1s ease-out',
        ...style,
      }}
      className={
        `${defaultClassStyles} ${buttonBgColor} ${buttonFont} ${buttonPadding} ${isFull} ${className}`
      }
      {...otherProps}
      disabled={otherProps.disabled}
    >
      {renderStartIcon}
      {children}
      {renderTrailingIcon}
    </button>
  );
};

export default Button;
