import { NAV_HEIGHT, Z_INDECES } from "@app/constants/default";
import {
  ABOUT_PAGE, BLOG_PAGE, CAREERS_PAGE,
  CONTACT_US_PAGE,
  HOME_PAGE
} from "@app/constants/routes";
import { mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useThemeContext } from "../../contexts/theme-context";
import Button from "../button";
import IconButton from "../icon-button";
import SidenNav from "./side-nav";

const Navbar = () => {
  const { isScrollPassedNav, isSidebarOpen, setIsSidebarOpen } =
    useThemeContext();

  const router = useRouter();
  const redirectToHome = () => router.push(HOME_PAGE);
  const isAtHomePage = useMemo(
    () => router.pathname === HOME_PAGE,
    [router.pathname]
  );
  const shouldExpandNav = isAtHomePage && !isScrollPassedNav;
  const navRoutes = [
    {
      label: "Home",
      path: HOME_PAGE,
      hasDivider: true,
    },
    {
      label: "About",
      path: ABOUT_PAGE,
      hasDivider: true,
    },
    {
      label: "Blog",
      path: BLOG_PAGE,
      hasDivider: true,
    },
    {
      label: "Contact",
      path: CONTACT_US_PAGE,
      hasDivider: false,
    },
  ];

  return (
    <>
      <div
        style={{
          height: shouldExpandNav ? "auto" : NAV_HEIGHT,
          zIndex: Z_INDECES.NAVBAR+1,
        }}
        className={`flex justify-center fixed transition-all duration-1000 font-montserrat ${
          shouldExpandNav
            ? "top-10 lg:top-32 left-0 right-0"
            : "top-0 left-0 right-0 p-4"
        }`}
      >
        <div
          className={`text-custom-white rounded-[40px] gradient-border 
        ${
          shouldExpandNav
            ? "w-full xl:w-[1040px] md:mx-8 lg:mx-10 lg:h-[492px] xs:h-[409px] h-[340px] xs:mx-6 mx-4"
            : "w-full h-[68px]"
        }`}
          style={{
            background:
              "linear-gradient(103.9deg, rgba(255, 255, 255, .1), rgba(0, 0, 0, 0.5))",
            transition: "width 1.5s, height 1s",
            backdropFilter: "blur(32px)",
            WebkitBackdropFilter: "blur(32px)",
          }}
        >
          <div
            className={`flex flex-col h-full ${
              shouldExpandNav ? "justify-between p-6 xs:p-10" : "justify-center px-6 xs:px-10"
            }`}
          >
            <div className="flex flex-row justify-between items-center">
              <div
                className="hidden sm:block cursor-pointer"
                onClick={redirectToHome}
              >
                <Image
                  alt="logo"
                  src={
                    shouldExpandNav
                      ? "/assets/svgs/global/logo-no-icon.svg"
                      : "/assets/svgs/global/logo.svg"
                  }
                  width={127}
                  height={32}
                />
              </div>
              <div
                className="sm:hidden cursor-pointer"
                onClick={redirectToHome}
              >
                <Image
                  alt="logo"
                  src="/assets/svgs/global/logo-icon-colored.svg"
                  width={29}
                  height={32}
                />
              </div>
              <div className="flex flex-row items-center space-x-2 xs:space-x-6">
                {navRoutes.map((route) => (
                  <div
                    key={route.path}
                    className="hidden lg:flex items-center space-x-6"
                  >
                    <Link href={route.path} passHref>
                      <span className="cursor-pointer btn-text-base hover:text-custom-white/50">
                        {route.label}
                      </span>
                    </Link>
                    {route.hasDivider && (
                      <div className="bg-custom-white h-4 w-[3px]" />
                    )}
                  </div>
                ))}
                <Button onClick={() => router.push(CAREERS_PAGE)}>
                  Join our Team
                </Button>
                <div className="lg:hidden">
                  <IconButton
                    icon={<Icon path={mdiMenu} size={0.8} />}
                    onClick={() => setIsSidebarOpen((prev) => !prev)}
                  />
                </div>
              </div>
            </div>
            <div
              className={`${
                shouldExpandNav
                  ? "bottom-10 pb-10 transition-all duration-1000"
                  : "-bottom-52 "
              } absolute max-w-[307px] sm:max-w-[512px] lg:max-w-[640px] md:px-10`}
            >
              <span className="h4 xs:h3 sm:h1 xl:text-8xl">
                We{" "}
                <span className="xs:hidden">
                  <Image
                    alt="logo"
                    src="/assets/svgs/global/logo-icon-white.svg"
                    width={32}
                    height={28}
                  />
                </span>
                <span className="hidden xs:inline sm:hidden">
                  <Image
                    alt="logo"
                    src="/assets/svgs/global/logo-icon-white.svg"
                    width={40}
                    height={40}
                  />
                </span>
                <span className="hidden sm:inline xl:hidden">
                  <Image
                    alt="logo"
                    src="/assets/svgs/global/logo-icon-white.svg"
                    width={64}
                    height={64}
                  />
                </span>
                <span className="hidden xl:inline">
                  <Image
                    alt="logo"
                    src="/assets/svgs/global/logo-icon-white.svg"
                    width={87}
                    height={87}
                  />
                </span>
                reate Experiences.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ zIndex: Z_INDECES.NAVBAR+1 }}
        className={`fixed transition-all duration-1000 bottom-0 top-0 ${
          isSidebarOpen ? "right-0 left-0" : "-right-52 left-full"
        }`}
        onClick={() => setIsSidebarOpen(false)}
      >
        <div className="flex w-full h-full justify-end">
          <SidenNav navRoutes={navRoutes} />
        </div>
      </div>
    </>
  );
};
export default Navbar;
