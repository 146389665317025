import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const styling = `
    text-shadow: 1px 1px 3px black;
    text-decoration: underline;
    font-size: 80px;
    font-family: 'Brush Script MT', cursive;
    color: #9D3FFC;
`;

console.log(
  '%cTeam Cogitate%c Be part of our growing team!',
  styling,
  'color: #735ef3;',
);

console.log('Apply now: https://cogitate.io')

console.log(' ')

console.log(`%cApp Version: Cogitate Web Template v${publicRuntimeConfig?.version}`,
  'background-color:#9D3FFC; padding: 5px 10px; color: white');
