import { useThemeContext } from "@app/contexts/theme-context";
import Image from "next/image";
import { useRef, useMemo } from "react";
import { Parallax } from "react-scroll-parallax";
import BottomFooter from "./bottom-footer";
import TopFooter from "./top-footer";

const Footer = () => {
  const { documentScrollTop, isMobile, isTablet } = useThemeContext();
  const footerRef = useRef(null);
  const { isNearBottom } = useMemo(() => {
    if (footerRef.current == null || window == null)
      return {
        isNearBottom: false,
      };
    const offsetTop = footerRef.current.offsetTop;
    const offsetHeight = footerRef.current.offsetHeight;
    const innerHeight = window.innerHeight;
    const scrollBottom = documentScrollTop + innerHeight;
    const footerEnd = offsetTop + offsetHeight;
    const isNearBottom = footerEnd - scrollBottom < 100;

    return {
      isNearBottom,
    };
  }, [documentScrollTop, footerRef]);

  return (
    <div
      className={`${
        isNearBottom ? "overflow-hidden" : "overflow-visible"
      } p-6 sm:p-10 xl:p-16 text-custom-white`}
      ref={footerRef}
    >
      <div className="relative">
        <div
          className={`absolute transition-all
          top-0
          -left-[700px] sm:-left-[600px] sm:-left-[400px] xl:-left-[500px] 2xl:-left-[400px]`}
          style={{
            transitionDuration: "2s",
          }}
        >
          <Parallax translateY={[isMobile || isTablet ? -10 : -40, 90]}>
            <Image
              alt="footer-rect"
              src="/assets/svgs/footer/footer-rect.svg"
              width={991}
              height={592}
              layout="fixed"
            />
          </Parallax>
        </div>
      </div>
      <div
        className="flex flex-col space-y-[72px] md:space-y-24 w-full rounded-[40px] gradient-border
          p-6 sm:p-16 overflow-visible"
        style={{
          background:
            "linear-gradient(180deg, rgba(79, 70, 229, 0.8), rgba(57, 54, 108, 0.4), rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.1))",
          backdropFilter: "blur(32px)",
          WebkitBackdropFilter: "blur(32px)",
        }}
      >
        <TopFooter />
        <BottomFooter />
      </div>
    </div>
  );
};
export default Footer;
