import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { capitalize, isArray } from 'lodash';
import { toast } from 'react-toastify';

const axiosConfig: AxiosRequestConfig = {
  baseURL: '/api',
  responseType: 'json',
};

const axiosMondayConfig: AxiosRequestConfig = {
  baseURL: 'https://api.monday.com/v2',
  responseType: 'json',
}

export const frontendApi = axios.create(axiosConfig);

export const backendApi = axios.create(axiosConfig);

export const mondayApi = axios.create(axiosMondayConfig);

export type AxiosRequestConfigExtendedType = AxiosRequestConfig & { _retry?: boolean }

frontendApi.interceptors.response.use(null, async (err: AxiosError) => {
  if (err.response) {
    const { status, data, statusText } = err.response || {};
    const message = data ? data.message : statusText;

    if (status >= 300 && status < 400) {
      toast.warning(message || 'Endpoint not found');
    }

    if (status >= 400 && status < 500) {
      if (status === 403 || status === 401) {
        toast.warning('Unauthorized Access', { toastId: 'unauth' });
      }

      /** Catch class-validator error format */
      if (isArray(message)) {
        message.map((m) => toast.warning(m ? capitalize(m) : 'Something went wrong'));
      }

      // default warning of 4xx
      toast.warning(message || 'Something went wrong');
    } else {
      // 5xx
      toast.error(message || 'Something went wrong');
    }
  } else {
    // This is usually misconfigured endpoint
    toast.error('Connection error. Please check your internet connection', { toastId: 'connerr' });
  }

  return Promise.reject(err);
});

backendApi.interceptors.response.use(null, async (err: AxiosError) => Promise.reject(err));

frontendApi.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

mondayApi.interceptors.request.use(
  async (config) => {
    const token = process.env.MONDAY_API_TOKEN;

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export const fetcher = (url) => frontendApi.get(url).then((res) => res.data);
