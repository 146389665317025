const colors = require('tailwindcss/colors');
const tailwindForms = require('@tailwindcss/forms');
const defaultTheme = require('tailwindcss/defaultTheme');
const plugin = require('tailwindcss/plugin');

const assignStyle = (fontSize, fontWeight, lineHeight, letterSpacing) => ({
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
});

module.exports = {
  mode: 'jit',
  purge: {
    content: [
      './pages/**/*.{js,ts,jsx,tsx}',
      './components/**/*.{js,ts,jsx,tsx}',
      './forms/**/*.{js,ts,jsx,tsx}',
    ],
    options: {
      keyframes: true,
    },
  },
  darkMode: 'class', // or 'media' or 'class'
  theme: {
    screens: {
      xs: '428px',
      ...defaultTheme.screens,
      '3xl': '1920px'
    },
    colors: {
      ...colors,
      transparent: 'transparent',
      current: 'currentColor',
      custom: {
        white: '#f8fafc',
        black: '#05071B',
        navy: '#01153B',
        violet: '#4F46E5',
        purple: '#9D3FFC',
        pink: '#D946EF',
      },
    },
  },
  variants: {
    extend: {
      backgroundColor: ['odd', 'even'],
      opacity: ['disabled'],
      cursor: ['disabled'],
      divideColor: ['dark'],
    },
  },
  plugins: [
    tailwindForms,
    plugin(({ addUtilities, addBase }) => {
      addUtilities({
        '.h1': assignStyle('72px', '700', '88px', '0px'),
        '.h2': assignStyle('60px', '700', '73px', '0px'),
        '.h3': assignStyle('40px', '700', '54px', '0px'),
        '.h4': assignStyle('30px', '700', '40px', '0px'),
        '.h5': assignStyle('24px', '700', '32px', '0px'),
        '.h6': assignStyle('18px', '700', '30px', '0px'),
        '.sub-heading-md': assignStyle('18px', '500', '28px', '0px'),
        '.sub-heading': assignStyle('18px', '400', '28px', '0px'),
        '.body-1': assignStyle('16px', '400', '24px', '0px'),
        '.body-2': assignStyle('14px', '400', '20px', '0px'),
        '.body-3': assignStyle('12px', '400', '16px', '0.25px'),
        '.label-lg': assignStyle('16px', '700', '24px', '0px'),
        '.label-md': assignStyle('14px', '700', '20px', '0px'),
        '.label-sm': assignStyle('12px', '700', '16px', '0px'),
        '.label-sm-extra': assignStyle('12px', '800', '16px', '0px'),
        '.btn-text-lg': assignStyle('16px', '500', '24px', '0px'),
        '.btn-text-base': assignStyle('14px', '500', '20px', '0px'),
        '.btn-text-sm': assignStyle('14px', '500', '16px', '0px'),
        '.btn-text-xs': assignStyle('12px', '500', '16px', '0px'),
        '.font-montserrat': { 'font-family': 'Montserrat', 'font-weight': 'auto', 'font-style': 'auto' }
      })
      addBase({
        h1: assignStyle('40px', '700', '54px', '0px'),
        h2: assignStyle('30px', '700', '40px', '0px'),
        h3: assignStyle('24px', '700', '32px', '0px'),
        h4: assignStyle('18px', '700', '30px', '0px'),
        h5: assignStyle('18px', '500', '28px', '0px'),
        h6: assignStyle('18px', '400', '28px', '0px'),
      });
    })
  ],
};
